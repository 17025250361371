import { css } from '@emotion/react'
import { COLORS } from 'Color'
import UserModalContent from 'components/molecules/UserModalContent'
import UserModalHeader from 'components/molecules/UserModalHeader'
import React from 'react'
import { Sns } from 'types/Sns'

interface Props {
	name: string
	userIndustry: string
	companyName: string
	image?: string
	tags?: string[]
	description: string
	sns: Sns
	userModalEnabled: boolean
	closeUserModal: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}
const UserModal: React.FC<Props> = ({
	name,
	userIndustry,
	companyName,
	image,
	tags,
	description,
	sns,
	closeUserModal,
}) => {
	const UserModalStyle = css`
		background: ${COLORS.translucentBlack};
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		> .wrapper {
			width: 620px;
			height: auto;
		}
	`
	return (
		<div css={UserModalStyle}>
			<div
				className="wrapper"
				onClick={(event) => {
					event.stopPropagation()
				}}>
				<UserModalHeader
					name={name}
					userIndustry={userIndustry}
					companyName={companyName}
					image={image}
					closeUserModal={closeUserModal}
				/>
				<UserModalContent tags={tags} description={description} sns={sns} />
			</div>
		</div>
	)
}

export default UserModal
