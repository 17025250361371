import { css } from '@emotion/react'
import React, { memo } from 'react'
import Noimage from '../../images/no-user-image.png'
// NOTE: 遅延読み込みコンポーネント
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface Props {
	image?: string
}

const image: React.FC<Props> = ({ image }) => {
	const imageStyle = css`
		max-width: 60px;
		max-height: 60px;
		height: auto;
	`
	return (
		<>
			{image !== '' && image != null ? (
				<LazyLoadImage css={imageStyle} src={image} alt="image" threshold={200} />
			) : (
				<LazyLoadImage css={imageStyle} src={Noimage} alt="Noimage" threshold={200} />
			)}
		</>
	)
}

export default memo(image)
