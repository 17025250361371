import { UserCardContents } from 'types/UserCardContents'

const userExample0 = {
	name: 'userExample0',
	userIndustry: 'ディレクター',
	companyName: '三菱UFJモルガンスタンレー証券株式会社',
	image: 'https://storage.googleapis.com/mcube-dummy-image/face0001.png',
	tags: ['tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6'],
	description: 'userIntroduction',
	sns: {
		facebook: '@test_000',
		instagram: 'https://www.facebook.com/yamada.taro',
		linkedin: '@test_test',
		twitter: '',
		twitterHandle: '',
	},
	authTime: '2022-10-05 12:42:00',
}
const userExample1 = {
	name: 'userExample1',
	userIndustry: 'ディレクター アートディレクトリー プロジェクトマネージャー',
	companyName: 'アルサーガパートナーズ株式会社',
	image: '',
	tags: ['tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6', 'tag7', 'tag8', 'tag9', 'tag10', 'tag11', 'tag12'],
	description:
		'userIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroductionuserIntroduction',
	sns: {
		facebook: '@test_000',
		instagram: 'https://www.facebook.com/yamada.taro',
		linkedin: '@test_test',
		twitter: '',
		twitterHandle: '',
	},
	authTime: '2022-10-05 12:42:00',
}

const userCardContentsArray: UserCardContents[] = []
for (let index = 0; index < 60; index++) {
	userCardContentsArray.push(userExample0)
	userCardContentsArray.push(userExample1)
}

const result = {
	userCardContentsArray,
	state: 200,
}

export default {
	async post() {
		await new Promise((resolve) => setTimeout(resolve, 1000))
		return { status: 200, data: result }
	},
}
