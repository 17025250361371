import React, { memo } from 'react'
import ReactDOM from 'react-dom'

interface Props {
	children: React.ReactNode
}
const ModalPortal: React.FC<Props> = ({ children }) => {
	const el = document.getElementById('modal') as Element
	return ReactDOM.createPortal(children, el)
}

export default memo(ModalPortal)
