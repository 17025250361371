import { css } from '@emotion/react'
import { COLORS } from 'Color'
import UserImage from 'components/atoms/UserImage'
import ModalPortal from 'components/organisms/ModalPortal'
import UserModal from 'components/organisms/UserModal'
import { useScrollLock } from 'hooks/useScrollLock'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { Sns } from 'types/Sns'

interface Props {
	name: string
	userIndustry: string
	companyName: string
	image?: string
	tags?: string[]
	description: string
	sns: Sns
}

const UserCard: React.FC<Props> = ({ name, companyName, image, userIndustry, tags, description, sns }) => {
	const userCardStyle = css`
		box-sizing: border-box;
		display: flex;
		align-items: flex-start;
		background: ${COLORS.whiteWhite};
		border-left: 8px solid ${COLORS.greenFir};
		box-shadow: 0px 4px 14px ${COLORS.blackShadow};
		border-radius: 8px;
		padding: 16px 24px 8px 16px;
		gap: 10px;
		margin-bottom: 16px;
		width: 333px;
		height: 163px;
		cursor: pointer;

		> .wrapper {
			display: inline-block;
			max-width: 209px;
		}

		> .wrapper > .name {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			color: ${COLORS.blackBlackOut};
			margin-bottom: 9px;
		}

		> .wrapper > .industry {
			font-style: normal;
			font-weight: 300;
			font-size: 13px;
			line-height: 13px;
			margin-bottom: 16px;
			height: 13px;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
		}

		> .wrapper > .company {
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: ${COLORS.blackBlackOut};
			text-overflow: ellipsis;
			margin-bottom: 16px;
		}

		> .wrapper > .tags {
			display: flex;
			flex-wrap: wrap;
			max-width: 209px;
			min-width: 0;
			max-height: 48px;
			overflow: hidden;
		}

		> .wrapper > .tags > .content {
			display: flex;
			flex-direction: center;
			padding: 2px 10px;
			background: ${COLORS.whiteLynxWhite};
			border: 1px solid ${COLORS.whiteSuperSilver};
			border-radius: 8px;
			margin-right: 8px;
			margin-bottom: 8px;

			&:last-child {
				margin-right: 0px;
			}
		}

		> .wrapper > .tags > .content > .text {
			display: inline-block;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 12px;

			color: ${COLORS.blackBlackOut};
		}
	`

	const [userModalEnabled, setUserModalEnabled] = useState<boolean>(false)

	useScrollLock(userModalEnabled)
	const openUserModal = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		document.addEventListener('click', closeUserModal)
		setUserModalEnabled(true)
		event.stopPropagation()
	}, [])

	const closeUserModal = useCallback((): void => {
		setUserModalEnabled(false)
		document.removeEventListener('click', closeUserModal)
	}, [])

	useEffect(() => {
		return () => {
			document.removeEventListener('click', closeUserModal)
		}
	}, [closeUserModal])
	return (
		<>
			<div
				css={userCardStyle}
				onClick={(event) => {
					openUserModal(event)
				}}>
				<UserImage image={image} />
				<div className="wrapper">
					<p className="name">{name}</p>
					{/* <p className="industry">{userIndustry}</p> */}
					<p className="company">{companyName}</p>

					{tags != null && !(tags.length === 0) && (
						<div className="tags">
							{tags.map((tag, index) => {
								return (
									<div className="content" key={`${tag}${index}`}>
										<span className="text">{tag}</span>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</div>
			{userModalEnabled && (
				<ModalPortal>
					<UserModal
						name={name}
						userIndustry={userIndustry}
						companyName={companyName}
						image={image}
						tags={tags}
						description={description}
						sns={sns}
						userModalEnabled={userModalEnabled}
						closeUserModal={closeUserModal}
					/>
				</ModalPortal>
			)}
		</>
	)
}

export default memo(UserCard)
