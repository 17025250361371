import { css } from '@emotion/react'
import { COLORS } from 'Color'
import React from 'react'

const Loading: React.FC = () => {
	const isLoadingStyle = css`
		background: ${COLORS.translucentBlack};
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		> .circle {
			box-sizing: border-box;
			width: 140px;
			height: 140px;
			border-radius: 50%;
			border: 10px solid ${COLORS.translucentGray};
			border-top-color: ${COLORS.whiteBrightWhite};
			animation: loading 1.2s linear infinite;
		}
		@keyframes loading {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	`

	return (
		<div css={isLoadingStyle}>
			<div className="circle"></div>
		</div>
	)
}

export default Loading
