import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import mock from 'data/mock/$mock'
import { AllUserContentsApi } from 'types/AllUserContentsApi'



const { REACT_APP_API_PATH, NODE_ENV, REACT_APP_API_KEY} = process.env

if (NODE_ENV === 'development') {
	const mockClient = mock()
	mockClient.setDelayTime(1000)
}

const getAllUserContents = async (): Promise<AllUserContentsApi> => {
		// NOTE: 環境変数でAPI pathをハンドリング
	const apiUrl = REACT_APP_API_PATH ?? '/user/allUserContents'
  const apiKey = REACT_APP_API_KEY

  let bodyInfo = {}
  let headers = {}
  if (apiKey !== undefined){
    // NOTE: 踏み台経由の呼び出し方
    bodyInfo =  {
      "isAll": false // NOTE: 表示非表示の影響を受けた状態にする為
    }
    headers = {
      "x-api-key": apiKey,
      Accept: "application/json, */*",
      "Content-type": "application/json",
    }
  }else{
    // NOTE: APIキー定義が無ければ従来の呼び方（localのmockもこちら）
		// console.log("apiKey is undefined")
  }
	const { data } = await axios.post<AllUserContentsApi>(apiUrl,bodyInfo, {headers: headers})

	return camelcaseKeys(data, { deep: true })
}

const useGetAllUserContentsQuery = <TData = AllUserContentsApi>(): UseQueryResult<TData, AxiosError> =>
	useQuery(['AllUserContents'], async () => await getAllUserContents(), {
		refetchOnWindowFocus: false, // NOTE: ホーカス時のフェッチをしない
		retry: false, // NOTE: エラー時にリトライしない
		staleTime: 30000, // NOTE: キャッシュの有効期限 30秒
		cacheTime: 30000, // NOTE: キャッシュが非参照の間 有効期限 30秒
		refetchInterval: 30000, // NOTE: 30秒に自動フェッチ
		refetchIntervalInBackground: true, // NOTE: タブ/ウィンドウがバックグラウンドにある間も再フェッチ
	})

export default useGetAllUserContentsQuery
