import { useEffect, useState } from 'react'

export const useScrollLock = (isLock: boolean): void => {
	const [scrollY, setScrollY] = useState(0)
	useEffect(() => {
		if (isLock) {
			const y = window.scrollY
			setScrollY(y)
			document.body.style.position = 'fixed'
			document.body.style.top = `-${y}px`
			document.body.style.height = 'auto'
		} else {
			document.body.style.position = ''
			document.body.style.top = ''
			document.body.style.height = ''
			window.scrollTo(0, scrollY)
		}
	}, [isLock])
}
