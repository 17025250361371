import { css } from '@emotion/react'
import UserCard from 'components/molecules/UserCard'
import useGetAllUserContentsQuery from 'hooks/useGetAllUserContentsQuery'
import React, { memo } from 'react'
import Loading from './Loading'

export const UserCards: React.FC = () => {
	const userCardsStyle = css`
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		&::after {
			content: '';
			display: block;
			width: 32%;
		}
	`

	const { data: allUserContents, isLoading, isFetching, isError, refetch } = useGetAllUserContentsQuery()

	if (isError) {
		window.alert('エラーが発生しました。しばらく経ってからご利用ください。')
		void refetch()
	}

	// TODO: 先方APIのレスポンスによってpropsは変動する可能性があります
	return (
		<div css={userCardsStyle}>
			{allUserContents?.result != null &&
				allUserContents.result.map((userCardContents, index) => (
					<div key={index}>
						<UserCard
							name={userCardContents.name}
							companyName={userCardContents.companyName}
							image={userCardContents.image}
							userIndustry={userCardContents.userIndustry}
							tags={userCardContents.tags}
							description={userCardContents.description}
							sns={userCardContents.sns}
						/>
					</div>
				))}
			{(isLoading || isFetching) && <Loading />}
		</div>
	)
}
export default memo(UserCards)
