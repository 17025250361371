import { css } from '@emotion/react'
import { COLORS } from 'Color'
import UserImage from 'components/atoms/UserImage'
import React, { memo } from 'react'

interface Props {
	name: string
	userIndustry: string
	companyName: string
	image?: string
	tags?: string[]
	closeUserModal: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const UserModalHeader: React.FC<Props> = ({ name, userIndustry, companyName, image, closeUserModal }) => {
	const userModalHeaderStyle = css`
		position: relative;
		box-sizing: border-box;
		display: flex;
		align-items: flex-start;
		background: ${COLORS.whiteWhite};
		padding: 42px 32px 24px;
		gap: 24px;
		width: 620px;
		height: 127px;
		border-bottom: 1px solid ${COLORS.whitePorpoise};
		border-radius: 8px 8px 0px 0px;
		flex: none;
		order: 0;
		flex-grow: 0;

		> .button {
			position: absolute;
			width: 14px;
			height: 14px;
			right: 32px;
			top: 24px;
		}
		> .button > .close {
			display: block;
			position: relative;

			&.close::before {
				content: '';
				position: absolute;
				top: 7px;
				left: 7px;
				width: 1px; /* 棒の幅（太さ） */
				height: 18px; /* 棒の高さ */
				background: ${COLORS.blackCavernous};
			}
			&.close::after {
				content: '';
				position: absolute;
				top: 7px;
				left: 7px;
				width: 1px; /* 棒の幅（太さ） */
				height: 18px; /* 棒の高さ */
				background: ${COLORS.blackCavernous};
			}
			&.close::before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&.close::after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}

		> .wrapper {
			height: auto;
		}

		> .wrapper > .name {
			height: 18px;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			color: ${COLORS.blackBlackOut};
			margin-bottom: 18px;
		}

		/* TODO: 先方表示するか検討中  */
		/* > .wrapper > .industry {
			height: 13px;
			font-style: normal;
			font-weight: 300;
			font-size: 13px;
			line-height: 13px;
			color: ${COLORS.blackBlackOut};
			margin-bottom: 16px;
		} */
		> .wrapper > .affiliation {
			height: auto;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: ${COLORS.blackBlackOut};
		}
	`

	return (
		<div css={userModalHeaderStyle}>
			<a className="button" onClick={(event) => closeUserModal(event)}>
				<span className="close"></span>
			</a>
			<UserImage image={image} />
			<div className="wrapper">
				<p className="name">{name}</p>
				{/* TODO: 先方表示するか検討中 */}
				{/* <p className="industry">{userIndustry}</p> */}
				<p className="affiliation">{companyName}</p>
			</div>
		</div>
	)
}

export default memo(UserModalHeader)
