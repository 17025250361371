/* Setting Layer */

// NOTE:
// 社内ITCSS-RSCSSガイドラインに基づいて命名してください
// 必ず下記リンクを一読すること
// https://docs.google.com/document/d/1kFQ-PGBg2nW1C2jaaG_3wCgNEOYTlc9HrzjjReL5Il0/edit#bookmark=id.omnyka6dfi72
// stylelint-disable-next-line scss/comment-no-empty
//
// (カラーの系統)-(カラー名)
// ex)
// カラーコード: #bf13cd
// $purple-awkward-purple: #bf13cd;

export const COLORS = {
	/* black */
	blackBlack: '#000',
	blackBlackOut: '#222222',

	blackDarkGrey: '#363636',
	blackSpanishGrey: '#989898',
	blackCavernous: '#525252',

	/* white */
	whiteWhite: '#ffffff',
	whiteDrWhite: '#fafafa',
	whitePorpoise: '#dadada',
	whiteLynxWhite: '#F7F7F7',
	whiteSuperSilver: '#eeeeee',
	whiteColdMorning: '#e5e5e5',
	whiteBrightWhite: '#f2f5ef',
	/* blue */

	/* green */
	greenFir: '#3b7263',
	/* red */

	/* shadow color */
	blackShadow: 'rgba(0, 0, 0, 0.1)',

	/* 透明色 */
	translucentBlack: 'rgba(0, 0, 0, 0.6)',
	translucentGray: 'rgba(242, 245, 239, 0.32)',
}
