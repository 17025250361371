import { css } from '@emotion/react'
import { COLORS } from 'Color'
import React, { memo } from 'react'
import { Sns } from 'types/Sns'
import TwitterLogo from '../../images/twitter-logo.png'
import InblueLogo from '../../images/inblue-logo.png'
import FacebookLogo from '../../images/facebook-logo.png'
import InstagramLogo from '../../images/instagram-logo.png'

interface Props {
	tags?: string[]
	description: string
	sns: Sns
}

const UserModalContent: React.FC<Props> = ({ tags, description, sns }) => {
	const userModalContentStyle = css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 24px 32px 32px;
		gap: 32px;
		width: 620px;
		height: auto;
		background: ${COLORS.whiteWhite};
		border-radius: 0px 0px 8px 8px;
		overflow: scroll;

		> .tags {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			max-height: 200px;
			gap: 12px 8px;
		}

		> .tags > .content {
			display: flex;
			flex-direction: center;
			padding: 2px 10px;
			background: ${COLORS.whiteLynxWhite};
			border: 1px solid ${COLORS.whiteSuperSilver};
			border-radius: 8px;

			&:last-child {
				margin-right: 0px;
			}
		}

		> .tags > .content > .text {
			display: inline-block;
			height: 12px;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 12px;

			color: ${COLORS.blackBlackOut};
		}

		> .introduction > .label {
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 13px;
			color: ${COLORS.blackSpanishGrey};
			flex: none;
			order: 0;
			flex-grow: 0;
			margin-bottom: 12px;
		}

		> .introduction > .content {
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 25px;
			color: ${COLORS.blackBlackOut};
			max-height: 144px;
			width: 556px;
			overflow: scroll;
		}

		> .sns {
			display: flex;
			align-items: center;
			gap: 12px;
		}
		> .sns > .logo {
			height: 32px;
			width: 32px;
		}
		> .sns > .logo > .img {
			width: 100%;
			height: auto;
		}
		> .sns > .content {
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
			color: ${COLORS.blackBlackOut};
		}
	`

	return (
		<div css={userModalContentStyle}>
			{tags != null && !(tags.length === 0) && (
				<div className="tags">
					{tags.map((tag, index) => {
						return (
							<div className="content" key={`${tag}${index}`}>
								<span className="text">{tag}</span>
							</div>
						)
					})}
				</div>
			)}
			{description !== '' && (
				<div className="introduction">
					<p className="label">自己紹介</p>
					<p className="content">{description}</p>
				</div>
			)}
			{sns.twitter !== '' && (
				<div className="sns">
					<div className="logo">
						<img className="img" src={TwitterLogo} />
					</div>
					<p className="content">{sns.twitter}</p>
				</div>
			)}
			{sns.linkedin !== '' && (
				<div className="sns">
					<div className="logo">
						<img className="img" src={InblueLogo} />
					</div>
					<p className="content">{sns.linkedin}</p>
				</div>
			)}
			{sns.facebook !== '' && (
				<div className="sns">
					<div className="logo">
						<img className="img" src={FacebookLogo} />
					</div>
					<p className="content">{sns.facebook}</p>
				</div>
			)}
			{sns.instagram !== '' && (
				<div className="sns">
					<div className="logo">
						<img className="img" src={InstagramLogo} />
					</div>
					<p className="content">{sns.instagram}</p>
				</div>
			)}
		</div>
	)
}

export default memo(UserModalContent)
