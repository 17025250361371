import { css } from '@emotion/react'
import { COLORS } from 'Color'
import UserCards from 'components/organisms/UserCards'
import React from 'react'

const TopPageContainer: React.FC = () => {
	const TopPageContainerStyle = css`
		max-width: 1030px;
		margin: 0 auto;

		> .header {
			padding: 16px 0;
		}

		> .header > .title {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
			flex: none;
			order: 0;
			flex-grow: 0;
			padding: 16px 0;
		}

		> .header > .supplement {
			font-weight: 300;
			margin-left: 16px;
			font-size: 13px;
			line-height: 13px;
			color: ${COLORS.blackBlackOut};
			vertical-align: text-bottom;
			flex: none;
			order: 1;
			flex-grow: 0;
			width: 352px;
			height: 13px;
		}
	`
	return (
		<div css={TopPageContainerStyle}>
			<div className="header">
				<span className="title">The M Cube 入館者一覧</span>
				<span className="supplement">OfficeRNDに登録されたプロフィールを元に表示しています</span>
			</div>
			<UserCards />
		</div>
	)
}

export default TopPageContainer
