import { css } from '@emotion/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { COLORS } from 'Color'
import TopPageContainer from 'components/templates/TopPageContainer'
import React from 'react'

const App: React.FC = () => {
	const appStyle = css`
		min-width: 1080px;
		background-color: ${COLORS.whiteColdMorning};
		width: 100vw;
		min-height: 100vh;
	`

	const client = new QueryClient()

	return (
		<QueryClientProvider client={client}>
			<section css={appStyle}>
				<TopPageContainer />
			</section>
			{process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
		</QueryClientProvider>
	)
}

export default App
